.barChartTypeConfiguration {
    margin-top: 15px;
}

.configureBarTypeText {
    font-size: 12px !important;
    font-weight: normal;
    color: #808C97;
    font-family: Lato;
    font-style: normal;
    margin-bottom: 10px;
}

.barTypeRadioButton {
    display: flex;
    flex-direction: row;
}

.xAxisConfigurationBox {
    margin-top: 15px;
}

.configureXAxisText {
    font-size: 12px !important;
    font-weight: bold;
    color: #808C97;
    font-family: Lato;
    font-style: normal;
    margin-bottom: 10px;
}

.xAxisConfiguration {
    margin-left: 20px;
}

.configureXAxisLabel {
    margin-bottom: 10px;
}

.configureXAxisFieldAndAggregator {
    display : flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.configureXAxisField {
    display : flex;
    flex-direction: column;
}

.configureXAxisAggregator {
    display : flex;
    flex-direction: column;
    margin-left: 5%;
    width: 45%;
}

.yAxisConfigurationBox {
    margin-top: 15px;
}

.configureYAxisText {
    font-size: 12px !important;
    font-weight: bold;
    color: #808C97;
    font-family: Lato;
    font-style: normal;
    margin-bottom: 10px;
}

.yAxisConfiguration {
    margin-left: 20px;
}

.configureYAxisLabel {
    margin-bottom: 10px;
}

.configureYAxisFieldAndAggregator {
    display : flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.configureYAxisField {
    display : flex;
    flex-direction: column;
    width: 50%;
}

.configureYAxisAggregator {
    display : flex;
    flex-direction: column;
    margin-left: 5%;
    width: 45%;
}


.subGroupingConfigurationBox {
    margin-top: 20px;
}

.subGroupingConfigurationBoxHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

.configureSubGroupingText {
    font-size: 12px !important;
    font-weight: bold;
    color: #808C97;
    font-family: Lato;
    font-style: normal;
}

.subGroupingToggleWrapper {
    margin-top: 0;
    width: fit-content;
}

.subGroupingToggle {
    margin: 5px;
}

.subGroupingConfiguration {
    margin-left: 20px;
}

.configureSubGroupingLabel {
    margin-bottom: 10px;
}

.configureSubGroupingFieldAndAggregator {
    display : flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.configureSubGroupingField {
    display : flex;
    flex-direction: column;
    width: 50%;
}

.configureSubGroupingAggregator {
    display : flex;
    flex-direction: column;
    margin-left: 5% !important;
    width: 45% !important;
}
