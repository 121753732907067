.approval-ctn{
    display: flex;
    width: 100%;
}

.approval-btn-ctn {
    width: 49%;
    min-width: 100px;
    max-width: 200px;
}

.approval-btn-ctn .success{
    width: 100%;
    margin-left: 20px;
}

.approval-btn-ctn .reject{
    width: 100%;
}
