.container {
  height: 100%;
  width: 100%;
}

.content {
  font-family: 'Lato';
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.header {
  font-weight: 700;
  font-size: 16px;
  margin: 20px 0 0 0px;
  font-family: 'Lato';
}

.toggleButton {
  font-weight: 700;
  float: right;
  color: #2A50E3;
  font-size: 9px;
  background: #d9d9d9;
  padding: 1px 5px;
  border-radius: 2px;
  margin-top: 5px;
  cursor: pointer;
}

.toggleButton:hover {
  background-color: #A9A9A9 !important;
}
