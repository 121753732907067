.tableColumnConfigurationBox {
    margin-top: 20px;
}

.configureTableColumns {
    font-size: 12px !important;
    font-weight: bold;
    color: #808C97;
    font-family: Lato;
    font-style: normal;
    margin-bottom: 15px;
}

.tableRowConfigDraggableListItem {
    width: 100%;
    margin: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.tableRowConfigDraggableListItemWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.tableRowConfigDraggableListItemNameTag {
    height: fit-content;
    align-self: center;
}
