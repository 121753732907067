.canvasStartNode {
    width: 210px;
    height: 33px;
    background: #F5F6FE;
    border: 2px solid #E8EAFF;
    border-radius: 5px;
    order: 0;
    padding: 3px 3px 3px 10px;
    display: flex;
    justify-content: space-around;
    font-size: 14px;
}

.canvasStartNode:hover {
    background: #D2FFCB;
    border-color: #A9EE9E !important;
}

.stageIcon {
    margin-right: 10px;
}

.canvasNodeHighlighted {
    border-color: #A9EE9E !important;
}

.canvasNodeSelected {
    border-color: #A9EE9E !important;
    background: #D2FFCB;
}

.canvasStartNodeLabel {
    cursor: pointer;
    width: 80%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
}

canvasStartNodeLabel::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

.canvasStartNodeDivider {
    border: 1px solid #E8EAFF;
    height: 23px;
    width: 0;
}

.canvasStartNodeEditIcon {
    cursor: context-menu;
    width: 18%;
    height: 100%;
    display: inline-flex;
    padding: 0.25rem 0.65rem;
}
