.stageDetails p {
  font-size: 12px;
  color: #78787a;
  margin-top: 0px;
}

.stageDetails h5 {
  margin-bottom: 0px;
}

.stageFormComponentWrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
}
