.profileRow {
    margin-top: 40px;
}

.rowTitle {
    font-weight: bold;
    font-size: 17px;
    display: flex;
}

.rowLabel {
    font-weight: bold;
}

.descriptionItem {
    display: flex;
    gap: 5px;
}

.profileDrawer {
    font-family: 'Lato', serif;
}

.profileIconWrapper {
    padding: 20px;
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 20px;
}
