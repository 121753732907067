.formPlaygroundPanel{
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    background-color:#ffffff;
}

.formPlaygroundPanelBodyBlur{
    filter: blur(1px);
}

.formPlaygroundPanel :global(.ant-collapse-arrow){
  font-size: 16px !important;
  vertical-align: 1.5px !important;
}

.formPlaygroundHeader {
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}

.formPlaygroundBody {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
  margin-bottom: 5px;
  justify-content: flex-start;
}

.fieldVisibility {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f3f3f3;
  height: 28px;
  width: 187px;
  font-size: 12px;
  position: absolute;
  right: 10%;
  font-weight: bold;
  border: 1px solid #f3f3f3;
  border-radius: 0.5rem;
}

.fieldVisibilityOptionUnselected {
  color: black;
  height: 100%;
  padding: 0 8px 0 8px;
  min-width: 25%;
  line-height: 25px;
  text-align: center;
}

.fieldVisibilityOptionSelected {
  color: #2A50E3 !important;
  background-color: white;
}
