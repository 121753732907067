.headerWrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
}

.backButton {
    cursor: pointer;
    border-radius: 5px;
}

.backButton:hover {
    background-color: lightgray;
}

.clearButton {
    color: #2A50E3;
    margin-right: 7px;
    font-size: 12px;
    cursor: pointer;
}

.clearButton:hover {
    font-weight: bold;
}

.addButton {
    display: flex;
    justify-content: center;
    color: #2A50E3;
    margin-top: 10px;
    margin-bottom: -12px;
    font-size: 12px;
    cursor: pointer;
    padding-top: 5.5px;
    padding-bottom: 5.5px;
}

.addButton:hover {
    display: flex;
    justify-content: center;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: -12px;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 12px;
    cursor: pointer;

    background-color: #fff;
    border-color: #d9d9d9;
    box-shadow: 0 2px 0 rgba(0,0,0,.02);
    border-style: dashed;
}

.applyButtonWrapper {
    display: flex;
    justify-content: end;
    margin-top: -10px;
}

.filterRow {
    display: flex;
    justify-content: space-between;
    background-color: #F5F6FE;
    gap: 10px;
    padding: 15px 10px;
    border-radius: 8px;
    margin: 5px 2px;
}

.firstFieldWrapper {
    width: 30%;
    min-width: 150px;
}

.operatorWrapper {
    width: 30%;
    min-width: 150px;
}

.secondFieldWrapper {
    width: 30%;
    min-width: 150px;
}

.deleteIcon {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    flex-direction: column;
    cursor: pointer;
}
