.inputName {
    padding: 0px !important;
    padding-left: 4px !important;
    font-weight: bold !important;
    font-size: 18px !important;
    border-radius: 0.2rem !important;
    border: 1px solid #d9d9d9 !important;
}

.inputName:hover {
    background-color: #dddddd !important;
}
.inputName:focus {
    border: 1px solid #40a9ff !important;
}
