.navbarContentsStyle {
    font-family: Lato;
    font-weight: 700;
    width: 100%;
}

.navbar {
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 991px) {
    .navbarContentsStyle{
        width: 100%;
    }
    .responsive_navbar_nav{
        margin-top: 50px;
        display: flex;
        justify-content: flex-end;
    }
    .navbar {
      padding-bottom: 10px;
      width: max-content;
      border-radius: 5px;
      z-index: 4 !important;
    }
    .navbar > a {
        margin-left: 50%;
        color: grey !important;
    }

    .navbar > button {
        border-top: 2px dotted gray;
    }
    .navbar > button:hover {
        border-top: 2px dotted gray;
    }
    .user_name_logo{
        padding-top: 10px !important;
    }

  }

.dropdownBtn, .dropdownBtn:focus, .dropdownBtn:active, .dropdownBtn:hover  {
    font-family: Lato;
    font-weight: 700;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    border: none;
}

.topNavBarBrand {
    display: flex;
    align-items: center;
}

.userName {
    width: 80px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
