.cardFormFieldWrapper {
    width: 48%
}

.cardFormLargeFieldWrapper {
    width: 97% !important;
}

.cardFormField {
  padding: 2px;
  margin: 2.5px 5px;
}

.cardFormFieldDisabled {
    background-color: #f3f3f3;
}

.cardFormField label{
    min-width: 100px;
    max-width: 200px;
    font-size: 12px;
    font-weight: 400;
    white-space: nowrap;
    overflow: scroll;
}

.cardFormFieldHeader{
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
}

.requiredCardFormField {
  display: flex;
  justify-content:space-between;
}

.cardFormFieldLabelIcon{
  display:flex;
}

.cardFormFieldLabelIcon img{
  margin-right: 0.45rem;
}

.cardFormFieldDeleteIcon{
  color: #78787A;
}

.requiredCardFormField label::after{
  content:"*";
  color: #ff0000;
}

.cardFormField input{
    display: flex;
    padding: 6px 12px;
    border: 1px solid #CED4DA;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
}

.cardFormFieldSelected{
  margin: 5px;
  background: #F5F6FE;
  border: 2px dashed #2A50E3;
  box-sizing: border-box;
  box-shadow: 2px 4px 6px 2px rgb(0 0 0 / 10%);
  border-radius: 4px;
}

.inputInvalid {
    border: 1px solid red !important;
}

.cardFormInputTextArea {
    width: 100%;
    height: 34px;
    border: 1px solid #CED4DA;
    border-radius: 4px;
    margin-top: 3px;
    padding-left: 10px;
}

.inputClassName {
    height: 39px;
    border: 1px solid #CED4DA !important;
    border-radius: 4px !important;
}
