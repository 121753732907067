.modalTitle{
    font-style: normal;
    font-weight: 700;
    font-family: Lato;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 29px;
    font-size: 24px;
}

.modalSubTitle {
    font-family: Lato;
    margin-top: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.01em;
}
