.addPlaceHolderButton {
    font-weight: 700;
    float: right;
    color: #2A50E3;
    font-size: 9px;
    background: #d9d9d9;
    padding: 1px 5px;
    border-radius: 2px;
    margin-top: 5px;
    cursor: pointer;
}

.addPlaceHolderButton:hover {
    background-color: #A9A9A9 !important;
}

.placeholderInputWrapper {
    margin-top: 10px;
}

.placeholderInputWrapper p{
    font-size: 12px !important;
    font-weight: normal;
    color:#808C97;
    margin: 0;
    font-family: Lato;
    font-style: normal;
}

.placeholderInputRequired p::after{
    content:"*";
    color: #ff0000;
}

.placeholderMention {
    background: #F9EED8;
    border-radius: 4px;
}
