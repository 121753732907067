.modalSubtitleRow {
    display: flex;
    flex-wrap: wrap;
}
.modalSubtitleContent {
    display: flex;
    align-items: center;
    margin-right: 15px;
    font-size: 12px;
    color: #808C97;
    margin-top: 5px;
}
