.fieldSettings{
    margin-left: 0.45rem;
}

.fieldSettingsHeader {
    display: flex;
    margin-top: 2px;
}
.fieldSettingsHeader p {
    margin-left: 10px;
    margin-top: -2px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
}

.inputFieldsCtn label{
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #808C97;
    margin-top: 5%;
}

.inputFieldsCtn label::after{
    content:"*";
    color: #ff0000;
}

.inputFieldsCtn input{
    width:100%;
    background: #FFFFFF;
    border: 1px solid #CED4DA;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 15px;
}

.fieldRequiredCtn{
    display: flex;
    justify-content:space-evenly;
    margin-top: 15px;
    padding: 13px 0px;
    border-top: #CED4DA 0.5px solid;
    border-bottom: #CED4DA 0.5px solid;
}

.fieldRequiredCtn p{
    font-style: normal;
    font-weight: bold;
    font-size: 14px !important;
    color: #060A0F;
}

.toggleCtn{
    margin-top: 3px !important;
}
