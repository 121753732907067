.workflowBuilderIcon {
    vertical-align: inherit;
    margin-right: 5px;
}
.workflowBuilderEditIcon {
    width: 24px;
}
.actionButton {
    margin-right: 15px;
}
