.placeholderOptionIcon {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #E9ECEF;
    width: 50px;
    padding: 8px 16px 8px 16px;
    border-radius: 5px;
}

.placeholderOptionText {
    height: 35px;
    width: 205px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    left: 50px;
    top: 5px;
    border-radius: 0px;
    padding: 8px 16px 8px 16px;
}

.placeholderOption:hover {
    background-color: #F3F3F3;
}

.placeholderOption {
    height: 45px;
    cursor: pointer;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #E9ECEF;
    border-radius: 10px;
    box-sizing: border-box;
}
