.createItemModalSubtitle {
    display: flex;
    margin-top: 5px;
}
.createItemModalSubtitleContent {
    margin-right: 15px;
    font-size: 12px;
    color: #808C97;
}

.workflowCardDisplay {
    margin-right: 15px;
    width: 160px !important;
    height: 201px;
}

.workflowCardText {
    font-size: 12px;
    color: #78787A;
    padding-left: 10px;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
}

.createItemButton {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    border: none;
    background: none;
    align-items: center;
    text-align: center;
    color: #2A50E3;
}
.createItemIcon {
    font-size: 14px;
    padding-right: 4px;
    color: #060A0F;
    font-weight: bold;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
