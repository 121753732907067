.actionDetailsFormItem {
    margin-bottom: 0px !important;
}

.emailToFormItem {
    margin-bottom: 10px !important;
}

.multipleValueNote {
    margin-top: 3px;
    margin-left: 5px;
    color: grey;
    font-size: 10px;
}

.actionDetailsPlaceholderItem {
    margin-bottom: 10px !important;
}
