.listItemInputWrapper {
    display: flex;
    gap: 5px;
    margin-bottom: 10px;
    margin-top: 20px;
}

.listWrapper {
    border-top: 0.05rem solid #A9A9A9;
    border-bottom: 0.05rem solid #A9A9A9;
    padding-top: 5px;
    padding-bottom: 5px;
}

.listItemWrapper {
    display: flex;
    justify-content: space-between;
    padding: 5px 5px;
}

.addItemButton {
    margin-top: 18px;
    margin-left: 5px;
}
