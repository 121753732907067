.quickActionButton {
    display: flex;
    background: #F6F6F6;
    color: white;
    border-radius: 64px;
    min-width: 80px;
    width: 80px;
    max-width: 150px;
    font-family: 'Lato', serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 14px;
    padding: 4px;
    justify-content: center;
    margin-bottom: 10px;
    margin-bottom: 2px;
    cursor: pointer;
    margin-left: 4px;
    border: 2px solid transparent;
}

.quickActionButtonShare{
    background: green;
}
.quickActionButtonShare:hover{
    background: white;
    color: green;
    border: 2px solid green;
}
.quickActionButtonDiscard{
    background: #ef5350;
}
.quickActionButtonDiscard:hover{
    background: white;
    color: #ef5350;
    border: 2px solid #ef5350 ;
}

.quickActionButtonEdit{
    background: #1976d2;
}
.quickActionButtonEdit:hover{
    background: white;
    color: #1976d2;
    border: 2px solid #1976d2 ;
}