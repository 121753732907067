.cardDropdownSelect > div {
    height: 39px !important;
    border: 1px solid #CED4DA !important;
    border-radius: 4px !important;
}

.cardDropdownRow {
    line-height: 36px;
}

.cardDropdownWrapper > div {
    border: none !important;
    padding-top: 2px;
}

.iconClassName {
    margin-top: 12px;
}
