.workflowItemsCanvasView {
    height: 50vh;
    border-bottom: 2px solid #F4F4F4;
    margin-bottom: 15px;
}

.canvasViewToggle {
    padding: 0rem 0.5rem 0.7rem 0rem;
    justify-content: flex-end;
}
