.placeholderHeader {
    display: flex;
    height: 8% !important;
    border-bottom: 1px solid #F3F3F3;
    margin-bottom: 8px;
}

.placeholderCloseButton {
    margin-top: 10%;
    padding: 3px;
}

.placeholderContainer {
    z-index: 100;
    position: absolute;
    width: 250px;
    padding: 5px;
    max-height: 600px;
    border-radius: 4px;
    border: 1px solid #F3F3F3;
    box-sizing: border-box;
    box-shadow: -2px 4px 4px rgba(0, 0, 0, 0.11);
    background-color: #FFFFFF;
}
