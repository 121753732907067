.nameTag {
    background-color: #f6e4c2;
    margin-right: 5px;
    border-radius: 5px;
    padding: 2px;
}

.groupNameTag {
    background-color: #FBCEB1;
}
