.inputGroupCustom {
    display: flex;
    flex-wrap: wrap;
}

.inputGroupCustom .inputCtn{
    width: 100%;
    height: 100%;
}

.inputCtn p{
    font-size: 12px !important;
    font-weight: normal;
    color:#808C97;
    margin: 0;
    font-family: Lato;
    font-style: normal;
}

.inputCtnControl {
    box-sizing: border-box;
    border: 1px solid #CED4DA;
    padding: 4px 8px;
    font-family: Lato;
    font-style: normal;
    border-radius: 5px !important;
}

.inputInvalid {
    border: 1px solid red !important;
}

.inputCtnRequired p::after{
    content:"*";
    color: #ff0000;
}
