.tableHeaderFormFieldWrapper {
    width: 48%
}

.tableHeaderFormLargeFieldWrapper {
    width: 97% !important;
}

.tableHeaderFormField {
  padding: 2px;
  margin: 2.5px 5px;
}

.tableHeaderFormFieldDisabled {
    background-color: #f3f3f3;
}

.tableHeaderFormField label{
    min-width: 100px;
    max-width: 200px;
    font-size: 12px;
    font-weight: 400;
    white-space: nowrap;
    overflow: scroll;
}

.tableHeaderFormFieldHeader{
  display: flex;
  justify-content: space-between;
}

.requiredTableHeaderFormField {
  display: flex;
  justify-content:space-between;
}

.tableHeaderFormFieldLabelIcon{
  display:flex;
}

.tableHeaderFormFieldLabelIcon img{
  margin-right: 0.45rem;
}

.tableHeaderFormFieldDeleteIcon{
  color: #78787A;
}

.requiredtableHeaderFormField label::after{
  content:"*";
  color: #ff0000;
}

.tableHeaderFormField input{
    display: flex;
    padding: 6px 12px;
    border: 1px solid #CED4DA;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
}

.tableHeaderFormFieldSelected{
  margin: 5px;
  background: #F5F6FE;
  border: 2px dashed #2A50E3;
  box-sizing: border-box;
  box-shadow: 2px 4px 6px 2px rgb(0 0 0 / 10%);
  border-radius: 4px;
}

.inputInvalid {
    border: 1px solid red !important;
}

.tableHeaderFormInputTextArea {
    width: 100%;
    height: 34px;
    border: 1px solid #CED4DA;
    border-radius: 4px;
    margin-top: 3px;
    padding-left: 10px;
}

.inputClassName {
    /*height: 39px;*/
    /*border: 1px solid #CED4DA !important;*/
    /*border-radius: 4px !important;*/
}
