.configurationContent {
  display: flex;
  width: 90%;
  flex-direction: row;
  justify-content: center;
}

.configurationList {
  background-color: #fff;
  margin: 10px;
  border-radius: 10px;
  padding: 10px;
}

.configurationSection {
  padding: 20px;
}

.header {
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.description {
  font-family: 'Lato';
  font-size: 12px;
}

.content {
  margin-top: 5px;
}

.preview {
  width: 300px;
  margin: 10px;
  background-size: contain;
  border-radius: 10px;
}

.variableFieldsDisplay {
  background-color: #fff;
  width: 300px;
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
}
