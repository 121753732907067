.inputArea {
    margin-bottom: 27px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.container {
    width: 100%;
    padding: 60px !important;
    overflow: hidden;
}

.searchBar {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.searchBar button {
    margin-top: 22px;
    margin-left: 15px;
}

.userSelectDropdownWrapper {
    min-width: 200px;
}
