.modal {
    width: 55% !important;
    max-width: 55% !important;
}

.itemPageHyperlink {
    margin-bottom: 20px;
    padding-left: 10px;
}
.itemPageHyperlink a {
    text-decoration: none;
    color: #2A50E3;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
}
