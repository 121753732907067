.itemDataStageWrapper {
  margin-top: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  border-top: 1px solid #ededed;
}

.stageFieldsWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  margin-top: -20px;
}
