.pinBox {
    width: 100%;
    margin: 10px 0;
    justify-content: center;
}

.verifyButton {
    margin: auto;
}

.verifyButton :global(.ant-btn-loading-icon) {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
}