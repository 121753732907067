.container {
    background: #efe7dd url("https://cloud.githubusercontent.com/assets/398893/15136779/4e765036-1639-11e6-9201-67e728e86f39.jpg") repeat;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
}

.message {
    color: #000;
    line-height: 18px;
    font-size: 15px;
    position: relative;
    max-width: 85%;
    word-wrap: break-word;
    background: #fff;
    float: right;
    padding: 16px;
    margin: 16px;
    -webkit-box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.1);
    box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.1);
}

.message:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    top: 0;
}

.messageLeft{
    border-radius: 0px 5px 5px 5px;
}

.messageLeft:after {
    border-width: 0px 10px 10px 0;
    border-color: transparent #fff transparent transparent;
    left: -10px;
}

.messageRight{
    border-radius: 5px 0px 5px 5px;
}

.messageRight:after {
    border-width: 0 0 10px 10px;
    border-color: transparent transparent transparent #fff;
    right: -10px;
}

.header {
    font-weight: bold;
}

.paragraph{
    margin-top: 10px;
}

.footer {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    color: #808080;
    font-size: 13px;
}
