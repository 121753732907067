.selectChartDialog {
    max-width: 60% !important;
}

.configureChartDialog {
    max-width: 80% !important;
}

.footerCtn {
    display: flex;
    justify-content: flex-end !important;
    border-top: 0px !important;
}

.chartTypeList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;


    justify-content: center;
    width: 100%;
    margin: 20px;
}

.chartTypeBox {
    margin: 20px;
    width: 150px;
}

.chartTypeButtonText {
    font-weight: bold;
}
