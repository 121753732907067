.signInLink {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #2A50E3;
    cursor: pointer;
}

.footerText {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 120%;
    color: #808C97;
    text-align: center;
    margin: 15px auto;
}

.changePasswordFieldIcon {
    margin-right: 10px;
    height: 12px;
    width: 12px;
    color: #78787A;
}
