.canvasFormNode{
    width: 210px;
    height: 33px;
    background: #E8EAFF;
    order: 0;
    padding: 2px 2px 2px 2px;
    display: flex;
    font-size: 14px;
    border-radius: 5px;
}

.canvasFormNode:hover {
    background: #A9EE9E !important;
}

.canvasFormNode:hover .innerNode {
    background: #D2FFCB !important;
}

.innerNode {
    width: 206px;
    height: 29px;
    background: #F5F6FE;
    order: 0;
    padding: 3px 3px 3px 8px;
    display: flex;
    justify-content: space-around;
}

.canvasApprovalNode{
    border-radius: 0.8rem;
}

.canvasConditionalNode {
    clip-path: polygon(5% 0%, 95% 0%, 100% 50%, 95% 100%, 5% 100%,0% 50%);
}

.canvasNodeHighlighted {
    border-color: #A9EE9E !important;
}

.canvasNodeSelected {
    border-color: #A9EE9E !important;
    background: #D2FFCB;
}

.canvasFormNodeSelected_Highlighted {
    background: #A9EE9E
}

.canvasFormNodeLabel {
    cursor: pointer;
    width: 80%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
}

canvasFormNodeLabel::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

.canvasFormNodeDivider {
    border: 1px solid #E8EAFF;
    height: 23px;
    width: 0;
}

.canvasFormNodeEditIcon {
    cursor: context-menu;
    width: 18%;
    height: 100%;
    display: inline-flex;
    padding: 0.25rem 0.65rem;
}

.stageIcon {
    margin-right: 10px;
}
