.sectionHeader {}

.sectionSubHeader {}

.sectionHeaderZone {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.sectionHeaderIcon {
  display: flex;
  margin-right: 5px;
  align-items: center;
}

.sectionHeaderText {
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
}

.sectionButtons {
  display: flex;
  gap: 7px;
  justify-content: space-evenly;
}