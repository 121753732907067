.dateRangePicker {
    width: 100%;
}

.dropdownLabel {
    margin-bottom: 0px !important;
    font-size: 12px !important;
    font-weight: normal;
    color: #808c97;
    margin: 0;
    font-family: Lato;
    font-style: normal;
}

.dropdownLabel::after{
    content:"*";
    color: #ff0000;
}
