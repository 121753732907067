.todoTable {
    padding: 40px;
}
.todoTableHeader {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}
.todoTableTitle {
    font-size: 20px;
    font-weight: 600;
}

.todoTableFilter {
    color: #2A50E3;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    padding-top: 20px;
    padding-right: 20px;
}

/* font family and font style to be moved to common css */
