.trigger {
    padding: 0 15px;
    font-size: 20px;
    line-height: 48px;
    cursor: pointer;
    transition: color 0.3s;
    color: white;
}

.trigger:hover {
    color: #1890ff;
}

.layout {
    width: 100%;
    background-color: transparent;
}

.navSidebar {
    background-color: #212529;
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 3;
}

.coreLayout {
    margin-left: 195px;
    transition: margin-left 0.3s;
    background-color: transparent;
}

.coreLayoutMarginCollapsed {
    margin-left: 65px !important;
}

.header {
    position: fixed;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 5vh !important;
    min-height: 50px !important;
    padding-left: 215px;
    padding-right: 10px;
    background-color: #212529;
    display: flex;
    transition: padding-left 0.3s;
}

.headerMarginCollapsed {
    padding-left: 85px !important;
}

.CoreSection {
    margin-top: 5vh;
    width: 100%;
    padding-bottom: 10px;
    height: 92vh;
    overflow-y: auto;
}
