.section {
    padding: 40px;
    position: relative;
    display: flex;
}

.listSection {
    display: inline-flex;
    width: auto;
    max-width: 100%;
    position: relative;
    overflow: auto;
    padding-bottom: 10px;
}

.sectionGradient::before {
    content: '';
    height: calc(100% - 90px);
    width: 10%;
    position: absolute;
    z-index: 1;
    right: 80px;
    top: 40px;
    background: linear-gradient(270deg, #FFFFFF 15.25%, rgba(255, 255, 255, 0) 117.36%);
}

.sectionGradientLeft::after {
    content: '';
    height: calc(100% - 90px);
    width: 10%;
    position: absolute;
    z-index: 1;
    left: 75px;
    top: 40px;
    background: linear-gradient(90deg, #FFFFFF 15.25%, rgba(255, 255, 255, 0) 117.36%);
}

.listSection::-webkit-scrollbar {
    display: none;
}

.scrollButtons {
    margin-top: 75px;
    width: 60px;
    height: 30px;
    background-color: #F4F4F4;
    border-radius: 2rem;
    padding: 5px;
    cursor: pointer;
    box-shadow: 1px 1px lightgray;
}

.listScrollRightButton {
    margin-left: 20px;
}

.scrollButtons:hover {
    background-color: white;
}

.listScrollLeftButton {
    margin-right: 5px;
}
