.container {
  width: 100%;
  padding: 50px !important;
  overflow: hidden;
}

.header {
  display: flex;
  gap: 5px;
}
