.tableLandingWrapper {
    padding-inline: 5px;
}

.headerWrapper {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
}

.mainButtons{
  display: flex;
}
