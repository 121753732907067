.container {
    width: 100%;
    padding: 50px !important;
}

.header {
    display: flex;
    gap: 10px;
}

.templateConfigurationWrapper {
    display: flex;
    background-color: whitesmoke;
    border-radius: 10px;
    padding: 20px;
}

.pdfConfigureWrapper {
    width: 25%;
    border-right: lightgrey 1px solid;
    padding: 10px 25px 10px 10px;
    gap: 20px;
    flex-direction: column;
    display: flex;
}

.templateDetailsForm {
    gap: 10px;
    flex-direction: column;
    display: flex;
}

.sectionHeader {
    font-size: 15px;
    font-weight: bold;
    color: black;
    font-family: Lato;
    border-bottom: lightgrey 1px solid;
    margin-bottom: 10px;
    padding: 5px 0px;
}

.actionButtons {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 10px;
}

.previewWrapper {
    width: 75%;
    max-height: 67vh;
    overflow: scroll;
}

.htmlPage {}

.htmlPage > div {
    position: unset !important;
    background: none !important;
}

.pdfGeneratorSteps {
    margin-top: 50px;
    background-color: whitesmoke;
    padding: 30px 50px;
    border-radius: 10px;
    color: grey;
}

.stepsList {
    margin-left: 10px;
    color: darkgray;
}
