.dropZone {
    margin-left: 18px;
    background-color: #FFFFFF;
    border: 2px dashed grey;
    box-sizing: border-box;
    border-radius: 4px;
    width: 95%;
    height: 30%;
    min-height: 50px;
    font-size: 15px;
    line-height: 35px;
    color: grey;
    margin-top: 16px;
    margin-bottom: 8px;
    justify-content: center;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding: 10px;
}

.isDropping {
    background-color: #f3f3f3;
}
