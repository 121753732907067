.tableWrapper {
    margin-top: 7px;
}

.addRecordButtonText {
    margin-left: 5px;
}
.addIcon {
    margin-top: -2px;
}

.hoverButton {
    cursor: pointer;
}

.hoverButtonRed:hover {
    color: red;
}

.hoverButtonBlue:hover {
    color: #0d6efd;
}

.hoverButtonGreen:hover {
    color: #28A745;
}
