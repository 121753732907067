.actionConfigurationsCollapse {
    background-color: unset !important;
    box-shadow: 2px 2px #ededed;
}

.actionConfigurationsPanel {
    margin-top: 7px;
    background-color: #fafafa;
    border-radius: 12px !important;
}

.actionCollapseHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.actionDeleteIcon {
    margin-top: 5px;
}
