.descriptionField {
    padding-top: 5px;
}

.chartConfiguration {
    border-bottom: 2px dashed #DEDFE8;
    display: flex;
    flex-direction: row;
    height: 60vh
}

.chartPreview {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 15px 10px 30px 20px;
    width: 50%;
    border-color:transparent;
    box-shadow:0 1px 2px -2px rgba(0,0,0,.16),0 3px 6px 0 rgba(0,0,0,.12),0 5px 12px 4px rgba(0,0,0,.09);
}

.chartParameters {
    margin-top: 10px;
    margin-bottom: 30px;
    padding-right: 20px;
    width: 50%;
    overflow-y: scroll;
}

.datePicker {
    padding-top: 2px;
    padding-bottom: 10px;
    gap: 5px;
    display: flex;
    align-items: center;
}

.datePickerLabel {
    font-weight: normal;
    font-size: 14px;
}

.chartDurationHeader {
    display: flex;
    flex-direction: row;
}

.chartDurationSameAsReportDurationToggleWrapper {
    margin: 5px;
    width: fit-content;
}

.chartDurationSameAsReportDurationToggle {
    margin: 5px;
}

.chartDuration {
    padding-bottom: 10px;
    padding-top: 10px;
}

.chartAdditionalDetails {

}

.otherFieldsTitle {
    font-weight: normal;
    font-size: 12px;
    color: #808C97;
    margin: 5px 0;
}

.labelMandatoryText {
    color: #FF0000;
}

.emptyTablePlaceholderContainer {
    width: 100%;
    display: flex;
    margin-top: 25px;
    margin-bottom: 25px;
    justify-content: center;
}
