.itemDetailsModalDialog {
    max-width: 75% !important;
    width: 75% !important;
}

.itemDetailsModalBody {
    display: flex;
    height: 67vh;
    padding-bottom: 30px;
}

.itemDetailsMainBodyWrapper {
    width: 65%;
    flex-direction: column;
    overflow-y: scroll;
    max-height: 67vh;
    border-right: 1px solid #dee2e6;
}

.stageNameLabel {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #060A0F;
}

.formWrapper {
    padding-left: 1rem;
}


.itemsDetailModalTitleTag{
    display: flex;
    flex-direction: row;
    align-content: center;
}

.renderQuickActions{
    display: flex;
    flex-direction: row;
    align-content: center;
}

.nextStageButton {
    margin-top: 10px;
}

.editMode{
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
    gap: 5px;
}
