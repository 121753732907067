.imageButtonCard {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-top: 0px;

    box-shadow: 2px 2px #ededed;
}

.selectedImageButtonCard {
    border: 2px solid #2A50E3;
    box-shadow:0 1px 2px -2px rgba(0,0,0,.16),0 3px 6px 0 rgba(0,0,0,.12),0 5px 12px 4px rgba(0,0,0,.09)
}

.selectedImageButtonCard:hover {
    border: 2px solid #2A50E3;
    box-shadow:0 1px 2px -2px rgba(0,0,0,.16),0 3px 6px 0 rgba(0,0,0,.12),0 5px 12px 4px rgba(0,0,0,.09)
}

.imageStyles {
    max-width:100%;
    max-height:100%;
    height: auto;
}

.buttonText {
    font-family: Lato;
    text-align: center;
    font-size: 15px;
    margin-top: 20px;
}
