.placeholderInput {
  line-height: 30px;
  font-size: 15px;
  font-family: Lato;
  border: none !important;
}


.placeholderInput--singleLine .placeholderInput__control:focus {
  border: none;
}

.placeholderInput--singleLine .placeholderInput__highlighter{
  background-color: white;
}

.placeholderInput--singleLine .placeholderInput__input {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.placeholderInput--singleLine .placeholderInput__input:focus {
  border: 1px solid #40a9ff !important;
  outline: none !important;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.placeholderInput--singleLine .placeholderInput__input:hover {
  border: 1px solid #40a9ff !important;
}

.placeholderInput--multiLine .placeholderInput__control:focus {
  border: none;
}

.placeholderInput--multiLine .placeholderInput__highlighter {
  background-color: white;
}

.placeholderInput--multiLine .placeholderInput__input {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.placeholderInput--multiLine .placeholderInput__input:focus {
  border: 1px solid #40a9ff !important;
  outline: none !important;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.placeholderInput--multiLine .placeholderInput__input:hover {
  border: 1px solid #40a9ff !important;
}

.placeholderInput__suggestions__list {
  min-width: 200px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
}

.placeholderInput__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.placeholderInput__suggestions__item--focused {
  background-color: #cee4e5;
}
