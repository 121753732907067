.genericCardFormDialog {
  max-width: 55% !important;
}

.footerCtn {
  display: flex;
  justify-content: flex-end !important;
  border-top: 0px !important;
}

.formContent {
  padding-left: 25px;
}
.stageName {
  font-size: 18px;
  font-weight: 700;
}

.modal {
  width: 55% !important;
  max-width: 55% !important;
}
