.toPhoneWrapper {
    display: flex;
    gap: 5px;
}

.countryCodeFieldInput {
    width: 75px;
    margin-top: 10px;
}

.toPhoneInput {
    width: 98%;
}
