.defaultTodoBodyWrapper {
    display: flex;
    width: 100%;
}

.defaultTodoLeftBodyWrapper {
    width: 60%;
    padding: 0px 20px;
}

.defaultTodoRightBodyWrapper {
    width: 40%;
    border-left: 1px solid #dee2e6;
    padding: 15px;
}

.modal {
    width: 50% !important;
    max-width: 50% !important;
}
