.placeholderOption:global(.ant-list-item) {
    padding-left: 10px;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    border: none;
    border-bottom: 1px solid #F3F3F3;
    border-radius: 5px;
}

.placeholderOption:global(.ant-list-item):hover {
    background-color: #F3F3F3;
}

.placeholderOptionIcon {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 9px;
    justify-content: center;
}

.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #F3F3F3;
}

.headerText {
    padding: 8px 12px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
}

.backButton {
    padding: 8px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
}

.backButton:hover {
    background: #E9ECEF;
}
