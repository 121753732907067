.otherFieldsHeader {
    border-bottom: 2px dashed #DEDFE8;
    padding-bottom: 10px;
    padding-top: 15px;
}
.actionTitle {
    display: flex;
    justify-content: space-between;
}
.otherFieldsTitle {
    width: 900%;
    font-weight: bold;
    font-size: 14px;
    padding-bottom: 10px;
}

.addActionButton {
    cursor: pointer;
}
