.emptyWrapper {
  width: 100%;
  justify-content: center;
  display: flex;
}

.emptyTableContainer {
  width: 100%;
  height: 300px;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
}

.emptyTableContainerSmall {
  height: 200px;
  width: 80% !important;
}

.emptyTableText {
  font-family: Lato;
  color: #A8A8A8;
}
