.label {
    font-size: 12px !important;
    font-weight: normal;
    color:#808C97;
    font-family: Lato;
    font-style: normal;
    margin-bottom: -8px;
}

.uploadFieldWrapper {
    border-radius: 5px !important;
}

.uploadAreaWrapper {
    height: 10px;
    display: flex;
    gap: 10px;
    justify-content: center;
}

.uploadIcon {
    margin-top: -1px;
}

.uploadText {
    margin-top: -5px;
    color: grey;
}

.fileItemWrapper {
    gap: 5px;
    display: flex;
    justify-content: space-between;
    margin: 6px 0px;
    padding: 4px 3px 3px 3px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
}

.fileItemTitle {
    width: 95%;
    color: grey;
    margin-top: -4px;
}

.fileItemWrapper:hover {
    background-color: #d9d9d9;
}

.icon {
    cursor: pointer;
}
