.footerWrapper {
    color: grey;
    font-family: Lato;
    font-weight: 300;
    font-size: 13px;
    padding: 10px;
}

.footerWrapper > span:hover {
    color: #1890ff;
    cursor: pointer;
}

.spanMargin {
    margin-left: 65px;
}

.copyright {
    margin-left: 40px;
    font-size: 11px;
}
