.genericButton .btnCtn,
.genericButton .btnCtnDashed {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    border-radius: 4px;
    border: none;
}

.genericButton .btnCtn > span,
.genericButton .btnCtnDashed > span {
    margin-left: 2px;
}

.genericButtonPrimary .btnCtn {
    background: #060A0F;
    color: #fff;
}

.genericButton .btnCtn:hover, .genericButton .btnCtn:focus {
    background: lightgray;
    color: #060A0F;
}

.genericButton .btnCtnDashed:hover, .genericButton .btnCtnDashed:focus {
    background: #fff;
    color: #1677ff;
    border-color: #1677ff;
}

.genericButtonDashed .btnCtnDashed {
    background-color: #fff;
    border-color: #d9d9d9;
    box-shadow: 0 2px 0 rgba(0,0,0,.02);
    border-style: dashed;
}

.genericButtonSecondary .btnCtn {
    background: #E8EDFF;
    color: #060A0F;
}

.genericButtonSuccess .btnCtn {
    background: #28A745;
    color: #fff;
}

.genericButtonReject .btnCtn {
    background: #DC3545;
    color: #fff;
}
