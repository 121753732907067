.container {
  width: 100%;
  padding: 60px !important;
  overflow: hidden;
}

.templatesSection {
  background-color: white;
  margin-top: 20px;
  padding: 5px;
  border-radius: 9px;
}