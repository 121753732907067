.signUpModal {
    height: 600px;
    width: 756px;
    border-radius: 4px;
}

.signUpModal:global(.modal-dialog) {
    height: 600px;
    width: 756px;
    max-width: 756px;
    margin: 40px auto;
    overflow: hidden;
    border-radius: 4px;
}

.signUpModal :global(.modal-content) {
    border: none;
}

.signUpModalContent {
    display: flex;
}

.signUpModalContent :global(.modal-footer) {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 120%;
    color: #808C97;

    display: flex;
    justify-content: center;
}

.signUpForm {
    background-color: #FFFFFF;
    width: 402px;
    border-radius: 4px;
    float: right;
    height: 600px;
    overflow: auto;
}

.signUpModalHeader:global(.modal-header) {
    border: none;
}

.signInLink {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #2A50E3;
    cursor: pointer;
}

.signInText {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 120%;
    color: #808C97;
    text-align: center;
    margin: 15px auto;
}

.signUpFieldIcon {
    margin-right: 10px;
    height: 12px;
    width: 12px;
    color: #78787A;
}

.signUpcountryCodeFieldInput {
    width: 20%;
    margin-left: 0;
}

.signUpPhoneNumberFieldInput {
    width: 100%   
}

.signUpPhoneField {
    display: flex; 
    height: 54px;
    gap: 5px;
    margin-bottom: 30px;
}

.signUpOptionsDivider {
    text-align: center;
    font-family: Lato;
    margin-top: 16px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 120%;
    color: #808C97;
}
