.tableHeaderUploadField {
    margin-top: 4px !important;
    height: 39px !important;
}

.tableHeaderUploadFieldReadOnly {
    display: none;
}

.tableHeaderUploadField > span {
    padding: 0px !important;
}
