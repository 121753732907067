.identityGenericButton {
    width: 100%;
    height: 46px;
    display: flex;
    padding: 8px 8px;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    margin: 20px auto;
}

.identityGenericButton span {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
}