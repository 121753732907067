.conditionRow {
    display: flex;
    justify-content: space-between;
}

.firstFieldWrapper {
    width: 30%;
    margin-top: -9px;
}

.operatorWrapper {
    width: 30%;
}

.secondFieldWrapper {
    width: 30%;
}

.deleteIcon {
    display: flex;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
}
