.chartHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.chartDetailsContainer {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.chartName {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
}

.chartDescription {
    font-family: Lato;
    font-style: italic;
    font-weight: normal;
    font-size: 15px;
}

.dateRangePickerContainer {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.chartContainer {
    flex: 1;
    height: calc(100% - 110px)
}

.emptyTablePlaceholderContainer {
    width: 100%;
    display: flex;
    margin-top: 25px;
    margin-bottom: 25px;
    justify-content: center;
}
