.identitiesModal {
    height: 600px;
    width: 756px;
    border-radius: 4px;
    box-shadow: 1px 1px lightgray;
}

.identitiesModal:global(.modal-dialog) {
    width: 756px;
    max-width: 756px;
    margin: 40px auto;
    overflow: hidden;
    border-radius: 4px;
}

.identitiesModal :global(.modal-content) {
    border: none;
}

.identitiesModalContent {
    display: flex;
    overflow: hidden;
    border-radius: 4px;
}

.identitiesModalContent :global(.modal-footer) {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 120%;
    color: #808C97;

    display: flex;
    border: none;
    justify-content: center;
}

.identitiesModalForm {
    background-color: #FFFFFF;
    width: 402px;
    border-radius: 4px;
    float: right;
    height: 100%;
    overflow: auto;
}

.identitiesModalHeader:global(.modal-header) {
    border: none;
}

.identitiesBackdrop {
    background-image: url(../../../../assets/backgroundImage.png);
    background-size: cover;
    opacity: 0.1 !important;
    background-blend-mode: exclusion;
    background-repeat: no-repeat;
}

.link {
    color: #2A50E3;
}
