.itemWrapper {
    display: flex;
    border-radius: 7px;
    margin: 5px;
    padding: 10px 20px;
    justify-content: space-between;
    box-shadow: 2px 2px 2px 0.1px rgb(0 0 0 / 20%);
    cursor: pointer;
    background-color: #ffffd8;
    font: Lato;
}

.itemSelected {
    background-color: #bcffb2;
}

.itemWrapper:hover {
    background-color: #D2FFCB;
}

.itemLeftWrapper {
}

.titleWrapper {
    display: flex;
}

.title {
    font-size: 15px;
}

.subTitle {
    font-size: 10px;
    font-style: italic;
    margin-left: 5px;
    color: grey;
}

.rightActionsWrapper {
    display: flex;
    gap: 10px;
    margin-top: -5px;
}

.tags {
    display: flex;
    margin-top: -5px;
}

.closeButton {
    margin-top: 12px;
}
