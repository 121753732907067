.cardTitle {
    padding-left: 10px;
    font-family: Lato;
    font-size: 18px;
    height: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    letter-spacing: 0em;
    text-align: left;
}

.card {
    border-radius: 10px !important;
    elevation: above;
    box-shadow: 1px 1px lightgray;
    max-width: 160px;
    width: 100%;
    background-color: white;
    height: 201px;
    margin-right: 10px;
}

.cardSelected {
    background: #D2FFCB;
}

.cardBodySelected:hover {
    background: #D2FFCB !important;
}

.card:hover {
    border-radius: 10px !important;
}

.cardBody {
    padding: 5px;
    height: 82%;
    min-width: 150px;
    width: 100%;
}

.cardBodyFullHeight {
    height: 100%;
}

.cardBody:hover {
    background-color: #F4F4F4;
    cursor: pointer !important;
    border-radius: 10px !important;
}

.cardBodySelected:hover {
    background-color: #D2FFCB !important;
}

.subTitle {
    margin-left: 10px;
}

.cardText {
    height: 68px;
}

.cardFooter {
    display: flex;
    height: 37px;
    border-top: 1px dashed #DEDFE8;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.cardFooter:hover {
    background-color: #F4F4F4 !important;
    border-radius: 10px !important;
}

.cardLabels {
    display: flex;
    height: 41px;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
}

.cardTag {
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #786E6E;
}

.cardAssignedBy {
    width: 20px;
    height: 20px;
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #FCD86D;
    border-radius: 10px;
}
