.descriptionField {
    padding-top: 5px;
}

.datePicker {
    padding-top: 2px;
    padding-bottom: 10px;
    gap: 5px;
    display: flex;
    align-items: center;
}

.datePickerLabel {
    font-weight: normal;
    font-size: 14px;
}

.otherFieldsHeader {
    border-bottom: 2px dashed #DEDFE8;
    padding-bottom: 10px;
    padding-top: 5px;
}

.otherFieldsTitle {
    width: 900%;
    font-weight: normal;
    font-size: 12px;
    color: #808C97
}

.labelMandatoryText {
    font-weight: normal;
    font-size: 12px;
    color: #FF0000
}
