.subConditionsContainer {
    background-color: #f5f6fe;
    padding: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 8px;
}

.logicalOperatorMaster {
    justify-content: space-around;
    width: 100%;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    color: #78787A;
}

.subConditions {
    display: flex;
    flex-direction: column;
}

.addConditionBtn {
    float: right;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    display: inline-block;
    color: #2A50E3;
    cursor: pointer;
    padding: 1px 5px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
}

.addConditionBtn:hover {
    background: #2A50E3;
    color: white;
}

.condition {
    display: flex;
}

.conditionText {
    margin-left: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-right: 5px;
}
