.alertDialogBackdrop {
    z-index: 10004 !important;
}

.alertModal {
    z-index: 10004 !important;
}

.alertDialog {
    margin-top: 80px !important;
    width: 25% !important;
    max-width: 25% !important;
    z-index: 10005 !important;
}

.modalContentWrapperReject {
    background-color: rgb(255, 245, 245) !important;
    box-shadow: 2px 2px #ffebee !important;
}

.modalContentWrapperSuccess {
    background-color: #e5fddc !important;
}

.bodyWrapper {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    margin-top: -10px;
}

.img {
    margin-bottom: 10px;
}

.footerWrapper {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}
