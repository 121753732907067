.genericTrowserWrapper {
    width: 100%;
    height: 100%;
    padding: 0.3rem;
    border-radius: 10px;
    border: 1px solid grey;
}

.genericTrowserHeader {
    display: flex;
    width: 100%;
    padding: 0.5rem;
    justify-content: space-between;
    border-bottom: 1px solid #f1f4ff;
    background-color: white;
}

.genericTrowserBody {
    width: 100%;
    height: 94vh;
    background-color: white;
}

.genericTrowserLeftButtons {
    display: flex;
}

.genericTrowserTitle {
    display: flex;
    align-items: center;
    margin-left: 10px;
}
