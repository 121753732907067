.companyCardBox {
    overflow-y: auto;
    display: flex;
    max-height: 450px;
    width: 100%;
    flex-direction: column;
}

.companyCardWrapper {
    font-family: 'Lato';
    background: #FFFFFF;
    border: 1px solid #EFEFEF;
    box-shadow: -2px 4px 4px rgba(158, 158, 158, 0.1);
    border-radius: 5px;
    margin-top: 15px;
    padding: 10px 20px 0px 20px;
    cursor: pointer;
}

.companyCardWrapper:hover {
    background: #F5FFF7;
    border: 0.5px solid #B5DDBE;
}

.companyNameText {
    font-weight: 700;
    font-size: 18px;
    color: #060A0F;
    overflow-wrap: anywhere;
}

.companySubText {
    font-weight: 400;
    font-size: 12px;
    color: #78787A;
    margin-top: 5px;
}
