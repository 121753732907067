.tooltipHeader {
  background-color: #FFF;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 20px 15px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.tooltipActions {
  margin-top: -5px;
  display: flex;
}

.stepFormContainer {
  width: 100%;
  border-radius: 10px;
}

.content {
  background-color: #F4F4F4;
  margin-bottom: 20px;
  min-height: 400px;
  width: 100%;
  overflow-y: auto;
  padding: 15px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.content::-webkit-scrollbar {
  display: inline;
  width: 10px;
}

.content::-webkit-scrollbar-thumb {
  background-color: #000;
  border-radius: 5px;
}

.actionButton {
  margin-left: 15px;
}

.footer {
  padding: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
}
