.addCommentsWrapper {
    display: flex;
    width: 100%;
    justify-content: space-around;
    height: 40px;
    margin-bottom: 15px;
}

.addCommentInput {
    width: 90%;
    margin-right: 5px;
    margin-top: -5px;
}

.addCommentButton {
    margin-top: 13px;
    margin-right: 1px;
    padding: 10% !important;
    height: 35.4px !important;
}

.commentsListWrapper {
    overflow-y: scroll;
    flex-direction: column;
    height: 100%;
    max-height: 45vh;
}

.itemCommentWrapper {
    margin-top: 2px;
    padding-top: 13px;
    border-top: #A8A8A8 0.01rem solid;
    display: flex;
    padding-left: 5px;
    width: 100%;
}

.commentDetailsWrapper {
    margin-left: 10px;
    width: 85%;
}

.commentText {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    word-wrap: anywhere;
}

.commentSecondLineWrapper {
    display: flex;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: #A8A8A8;
    margin-top: 2px;
}

.commentTimeWrapper {
    display: flex;
    margin-left: 10px;
}
