.googleSignInButton {
    height: 48px;
    width: 354px;
    left: 0px;
    top: 0px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: -0.01em;

    cursor: pointer;
    margin: auto;
}

.googleIcon {
    padding-right: 12px;
}