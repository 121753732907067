.placeholderTextView {
    line-height: 27px;
    font-size: 15px;
    font-family: Lato;
    border: none !important;
}


.placeholderTextView--singleLine .placeholderTextView__control:focus {
    border: none;
}

.placeholderTextView--singleLine .placeholderTextView__highlighter {
    background-color: white;
}
.placeholderTextView--singleLine .placeholderTextView__input {
    border: none;
}

.placeholderTextView--singleLine .placeholderTextView__input:focus {
    border: none;
}

.placeholderTextView--singleLine .placeholderTextView__input:hover {
    border: none;
}

.placeholderTextView--multiLine .placeholderTextView__control:focus {
    border: none;
}

.placeholderTextView--multiLine .placeholderTextView__highlighter {
    border: none;
    background-color: white;
}

.placeholderTextView--multiLine .placeholderTextView__input {
    border: none;
}

.placeholderTextView--multiLine .placeholderTextView__input:focus {
    border: none;
}

.placeholderTextView--multiLine .placeholderTextView__input:hover {
    border: none;
}
