.cronFieldWrapper {
    padding-left: 10px;
}

.cronFieldLabel {
    font-size: 13px !important;
    font-weight: normal;
    color: #808c97;
    margin-bottom: 10px;
    font-family: Lato;
    font-style: normal;
}

.cronFieldLabel::after{
    content:"*";
    color: #ff0000;
}

.cronField {
    font-size: 14px;
}
