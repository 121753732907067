.reportEditButton {
    width: fit-content;
    margin: 10px;
}

.reportEditActionButtons {
    display: flex;
    flex-direction: column;
}

.reportEditActionButton {
    width: calc(100% - 20px);
    margin: 10px;
}
