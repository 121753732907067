.footerButton {
   width:105px;
}

.conditionalStageTrue {
   color: green;
}

.conditionalStageFalse {
   color: red;
}
.conditionBodyScroll {
   overflow-y: scroll;
   height: 60vh;
}

.conditionBodyScroll::-webkit-scrollbar {
   width: 4px;
   background: #E1E1E1;
   border-radius: 24px;
}

.modal {
   width: 55% !important;
   max-width: 55% !important;
}
