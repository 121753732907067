.itemActivityWrapper {
    height: 100%;
    width: 35%;
    padding-left: 15px;
    flex-direction: column;
}

.itemActivityTabs {
    height: 100%;
}
