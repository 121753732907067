.stageHeader {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px dashed #DEDFE8;
    padding-bottom: 10px;
}

.stageNameWrapper {
    width: 70%;
}

.stageNameTitle {
    padding-left: 0px !important;
    font-weight: bold !important;
    font-size: 18px !important;
}
.editForm {
    width: 28%;
    color: #2A50E3;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    cursor: pointer;
}
.otherFieldsHeader {
    border-bottom: 2px dashed #DEDFE8;
    padding-bottom: 10px;
    padding-top: 15px;
}
.otherFieldsTitle {
    font-weight: bold;
    font-size: 14px;
    padding-bottom: 10px;
}
.datePicker {
    padding-top: 10px;
    padding-bottom: 10px;
    gap: 5px;
    display: flex;
    align-items: center;
}
.editProcessIcon {
    margin-top: -2px;
    padding-right: 5px;
    align-items: center;
}
.saveStageButton {
    margin-top: 20px;
}

.deleteStageButton:global(.ant-btn) {
    margin-top: 20px;
    margin-right: 10px;
    background: red;
}

.stageButtons {
    display: flex;
    justify-content: flex-end;
}
