.sideBarWrapper {
    padding: 5px 0px;
}

.topNavBarLogo {
    height: 38px !important;
    margin-bottom: 5px;
    padding-left: 30px;
}

.menu {
    height: 76vh;
    background-color: transparent !important;
}
