.tableTitle {
    font-size: 12px !important;
    font-weight: normal;
    color: #808C97;
    margin: 0;
    font-family: Lato;
    font-style: normal;
}

.tableRequired::after{
    content:"*";
    color: #ff0000;
}

.tableDropdown {
    padding-top: 5px;
}

.recordDropdownSelect > div {
    height: 39px !important;
    border: 1px solid #CED4DA !important;
    border-radius: 4px !important;
}

.recordDropdownRow {
    line-height: 36px;
}
