.formBuilderPaletteItem {
    width: 100%;
    height: 9%;
    min-height: 30px;
    max-height: 50px;
    display: flex;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #060A0F;
    margin: 0.25rem 0.25rem 0.5rem 0.25rem;
    border-radius: 3px;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px;
    background: #E8EDFF;
    box-sizing: border-box;
    box-shadow: 2px 2px 2px 0.1px rgb(0 0 0 / 20%);
}


.paletteIcon{
    margin-right: 0.75rem;
}
