.genericModal {
  margin-top: 8%;
}

.genericModalContentWrapper {
  border-radius: 15px !important;
  border: none !important;
}

.modalBody {
  padding: 1em 1em 0 1em !important
}

.footerCtn {
  display: flex;
  justify-content: flex-end !important;
  border-top: 0px !important;
}

.modalContent {
  padding: 10px;
  max-height: 67vh;
}

.modalContentScroll {
  overflow-y: scroll;
}

.modalHeader {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.noHeader {
  border-bottom: none !important;
}

.modalTitle {
  display: flex;
  justify-content: space-between;
}

.formContent {
  padding-left: 25px;
}

.leftHeader{
  display: flex;
}

.rightHeader{
  display: flex;
  gap: 5px;
}
