.gearIcon {
    margin-left: 15px;
    margin-top: 3px;
}

.settingsHeaderWrapper {
    margin-top: 1px;
    margin-bottom: 1px;
    display: flex;
    justify-content: space-between;
}

.clearButton {
    color: #2A50E3;
    margin-right: 7px;
    margin-top: 1px;
    font-size: 13px;
    cursor: pointer;
}
