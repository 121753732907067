.templatesWrapper {
    display: flex;
}

.templateModal {
    min-width: 800px;
}

.contentWrapper {
    min-height: 400px;
    background-color: #E5E5E5 !important;
}

.templateCard {
    height: 130px !important;
}
