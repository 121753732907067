.createCustomCard {
    cursor: pointer;
    border-radius: 10px !important;
    box-shadow: 1px 1px lightgray;
    margin-right: 2px;
}

.createCustomCardDisabled {
    cursor: not-allowed;
    background-color: #F4F4F4 !important;
    box-shadow: -5px 10px 10px rgba(158, 158, 158, 0.1) !important;
}

.createCustomIcon {
    background-color: #EDEDED;
    width: 42px;
    height: 42px;
    margin-bottom: 8px;
    border-radius: 21px;
}

.createCustomCardTitle {
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #757575
}

.createCustomCardDisplay {
    width: 160px;
    height: 201px;
    background: #FFFFFF;
    box-sizing: border-box;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px !important;
}

.createCustomCardDisplay:hover {
    background-color: #F4F4F4 !important;
    box-shadow: -5px 10px 10px rgba(158, 158, 158, 0.1);
}
