.table {
    background: #FFFFFF;
    border: 1px solid #EDEDED;
    border-radius: 6px;
    height: calc(100% - 110px);
    overflow: auto;
}

.tableContents {
    padding: 6px;
}

.titleClickable {
    cursor: pointer;
}

.removeBtn {
    cursor: pointer;
    color: red;
}

.emptyTablePlaceholderContainer {
    width: 100%;
    display: flex;
    margin-top: 25px;
    margin-bottom: 25px;
    justify-content: center;
}

.tableMenuOptionIcon {
    display: flex;
    margin-right: 5px;
    align-items: center;
}

.tableMenuOption {
    display: flex;
    margin-left: 15px;
}

.tableWrapper {
    background-color: #FFFFFF;
    height: 100%;
    width: 100%;
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    border-radius: 6px;
    padding-inline: 5px;
}

.tableWrapper::-webkit-scrollbar {
    /*display: none;*/
}

.tableControlsMenu {
    flex: 1;
    display: flex;
    font-family: Lato;
    justify-content: space-between;
    padding: 10px;
}

.tableControlsMenuLeft {
    display: flex;
}

.tableFarControls {
    display: flex;
    justify-content: space-between;
}

.header {
    background: #F4F4F4;
    border: 1px solid #EFEFEF;
    box-sizing: border-box;
    height: 40px;
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
}

.tableSkeletonContainer {
    align-items: center;
    flex-direction: column;
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 5px;
}

.skeletonBlock {
    width: 98%;
    margin-top: 5px;
}

table {
    width: 100%
}

tr {
    height: 50px;
    border-bottom: 1px solid #EFEFEF !important;
    font-size: 14px;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;

}

td,th {
    padding: 10px;
}

td {
    white-space: nowrap;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
}

thead {
    font-family: Avenir;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #757575;
}

.tableRow:hover {
    background-color: #F4F4F4 !important;
}

.tableFooter {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.excelDownloadButton{
    background: #E8EDFF;
    color: #060A0F;
    font-weight: 500;
    font-size: 14px;
    padding: 6px 12px;
    border-radius: 4px;
    text-decoration: none;
    border: 2px solid transparent;
}

.excelDownloadButton:hover{
    border: 2px solid #E8EDFF;
    background: #fff;
    color: #060A0F;
}

.excelDownload{
    padding: 0.5rem 0rem 0.5rem 0rem;
    display: flex;
    justify-content: flex-end;
}
