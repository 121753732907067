.reportContainer {
    width: 100%;
    padding: 0 20px 20px;
}

.reportHeader {
    width: 100%;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
}

.reportControls {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
}

.reportButtons {
    width: fit-content;
    height: 100%;
    display: flex;
    flex-direction: row;
    margin-right: 50px;
    /*width: 100%;*/
    justify-content: space-between;
    align-items: center;
}

.reportSelector {
    display: flex;
    flex-direction: column;
    /*width: 100%;*/
    justify-content: space-between;
}

.reportDetailsContainer {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.reportName {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
}

.reportDescription {
    font-family: Lato;
    font-style: italic;
    font-weight: normal;
    font-size: 17px;
}

.reportDurationRangePicker {
    display: flex;
    flex-direction: column;
    margin: 5px;
}

.reportHeaderBodyDivider {
    margin: 10px 0 0;
}

.reportResizableGridLayout {
    width: 100%
}

.deleteChartButton {
    position : absolute;
    z-index: 1;
    right: -10px;
    top: -10px;
    box-shadow:0 1px 2px -2px rgba(0,0,0,.16),0 3px 6px 0 rgba(0,0,0,.12),0 5px 12px 4px rgba(0,0,0,.09) !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.noReportSelectedText {
    font-weight: 600;
    font-size: 24px;
}

.emptyTablePlaceholderContainer {
    width: 100%;
    display: flex;
    margin-top: 25px;
    margin-bottom: 25px;
    justify-content: center;
}
