.site-drawer-render-in-current-wrapper {
    height: 100%;
    width: 100%
}
.ant-drawer-body {
    padding-top: 10px !important
}
.ant-picker {
    width: 100%;
}
.ant-dropdown-trigger {
    width: 100%;
}
.ant-select {
    width: 100%;
}