.auditWrapper {
    display: flex;
    font-family: 'Lato';
}

.auditDataWrapper {
    margin-top: 0;
    margin-left: 4px;
}

.activityStatus {
    margin-top: -4px;
}

.auditTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

.auditTime {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: #A8A8A8;
}

.auditListWrapper {
    overflow-y: scroll;
    flex-direction: column;
}
