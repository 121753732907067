.configureFiltersBox {
    margin-top: 10px;
    margin-bottom: 10px;
}

.chartFiltersComponent {
    border: 2px dashed #EDEDED;
    margin: 10px;
    padding: 20px 10px;
}

.addFiltersToggleWrapper {
    margin: 20px 0 0;
    width: fit-content;
}

.addFiltersToggle {
    margin: 5px;
}
