.formModalDialog {
    max-width: 60% !important;
}

.formModalDialog :global(.modal-body){
    padding: 0;
}

.formModalContent {
    min-height: 80vh;
    max-height: 90vh;
}

.formModalBody {
    max-height: 75vh;
}

.formBuilderBodyWrapper {
    display: flex;
    height: 70vh;
}

.formModalBackdrop {
    z-index: 1055;
}

.formBuilderLeftDrawer{
    align-items: center;
    width: 30%;
    padding: 0.5rem;
    border-right: 1px solid #E8EDFF;
    min-height: 90% !important;
    max-height: 95% !important;
    overflow-y: scroll;
}

.buttonCtn{
    display: flex;
}

.individualButton{
    margin-left: 5px;
    margin-right: 5px;
}

.formBuilderPlayground {
    width: 100%;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
}

.formBuilderFieldViewWrapper {
    overflow-y: scroll;
    min-height: 50%;
    height: 100%;
}
