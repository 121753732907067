.workflowHomepage {
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: 20px;
}
.workflowHeaderSection {
  display: flex;
  justify-content: space-between;
}

.workflowIcon {
  size: 24px;
}

.workflowNameSection {
  width: 100%;
  display: flex;
  padding-top: 20px;
}

.workflowName {
  width: 100%;
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  color: #060a0f;
  margin-left: 5px;
}

.workflowButtons {
  display: flex;
  padding-top: 20px;
  gap: 8px;
}

.newItemButton {
  margin-right: 10px;
  background-color: black;
  border: none;
  color: white;
}

.editProcessButton {
  margin-right: 10px;
  background-color: #e8edff;
  border: none;
  color: black;
}

.itemDetails {
  padding: 10px;
}
