.homepageHeader {
    margin-left: 40px;
}
.homepageHeaderSection {
    display: flex;
}
.homepageHeaderTitle {
    font-family: Avenir; 
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 33px;
    color: #060A0F;
    margin-left: 5px;
}
.homepageHeaderContent {
    font-size: 14px;
    line-height: 19px;
    color: #545E69;
}

/* font family and font style to be moved to common css */