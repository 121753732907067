.filterMenuContentWrapper {
    min-width: 250px;
    width: 100%;
}

.filterHeaderWrapper {
    margin-top: 1px;
    margin-bottom: 1px;
    display: flex;
    justify-content: space-between;
}

.filterButtonText {
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    color: #2A50E3;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.tableMenuOptionIcon {
    display: flex;
    margin-right: 5px;
    align-items: center;
}

.tableMenuOption {
    display: flex;
    margin-left: 15px;
}

.clearFilterButton {
    color: #2A50E3;
    margin-right: 7px;
    margin-top: 1px;
    font-size: 13px;
    cursor: pointer;
}
