.assigneeTitle {
    font-size: 12px !important;
    font-weight: normal;
    color: #808C97;
    margin: 0;
    font-family: Lato;
    font-style: normal;
}

.assigneeRequired::after{
    content:"*";
    color: #ff0000;
}

.assigneeDropdown {
    padding-bottom: 10px;
}
