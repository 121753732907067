.tag {
    margin-top: 4px;
    font-family: Lato;
    font-weight: 600;
    margin-left:0.7rem;
    text-align: center;
    padding: 0.15rem;
    font-size: 12px;
    border-radius: 6px;
    border-width: 0.07rem;
    border-style: solid;
    height: 25px;
}

.tagDark{
    color: black;
    border-color: black;
}

.tagActive{
    color: green;
    border-color: green;
}

.tagCompleted{
    color: green;
    border-color: green;
}


.tagError{
    color: red;
    border-color: red;
}

.tagWarn{
    color: #F4C430;
    border-color: #F4C430;
}

.tagPending{
    color: #03A9F4;
    border-color: #03A9F4;
}

.tagMultiSelect{
    color: #808C97;
    border-color: #f6e4c2;
    background-color: #f6e4c2;
    margin-top: 0 !important;
    margin-left: 0 !important;
    margin-right: 0.7rem !important;
}

.removeButton {
    margin-top: -2px;
    margin-left: 3px;
    text-align: center;
    cursor: pointer;
}
