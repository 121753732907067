.container {
  width: 100%;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}

.textArea {
  margin-bottom: 5px;
  width: 100%;
  font-family: 'Lato';
  font-size: 12px;
}