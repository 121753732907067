.editableChartCard {
    background-image: linear-gradient(135deg, rgba(0, 0, 0, 0.1) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1) 75%, transparent 75%, #fff);
    background-size: 5px 5px;
}

.chartCard {
    /*margin: 20px;*/
    /*height: calc(100% - 40px);*/

    height: 100%;
    border-color:transparent;
    box-shadow:0 1px 2px -2px rgba(0,0,0,.16),0 3px 6px 0 rgba(0,0,0,.12),0 5px 12px 4px rgba(0,0,0,.09)
}

.chartHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.chartDetailsContainer {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.chartName {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
}

.chartDescription {
    font-family: Lato;
    font-style: italic;
    font-weight: normal;
    font-size: 15px;
}

.dateRangePickerContainer {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.chartContainer {
    flex: 1;
    height: calc(100% - 110px)
}

.emptyTablePlaceholderContainer {
    width: 100%;
    display: flex;
    margin-top: 25px;
    margin-bottom: 25px;
    justify-content: center;
}

