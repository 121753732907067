.infoIcon {
    margin-top: -18px;
}

.infoContentWrapper {
    font-size: 11px !important;
    max-width: 300px;
    color: #808C97 !important;
}

