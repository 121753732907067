.settingsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.settingsBlock {
  background-color: #fff;
  margin-top: 20px;
  width: 40%;
  padding: 15px;
  border-radius: 15px;
  box-shadow: 1px 1px lightgray;
}

.settingsHeader {
  font-family: 'Lato';
  font-weight: 600;
  line-height: 30px;
  font-size: 16px;
}

.settingsSubHeader {
  font-family: 'Lato';
  font-size: 13px;
}

.settingsBody {
  margin-top: 10px;
}

.whatsappTemplateCategoryRadioButton {
  display: flex;
  flex-direction: column;
}
