.approvalDisplayText {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.01em;
    color: red;
}

.approvedTextStyle {
    color: green;
}

.iconWrapper {
    margin-top: -4px;
    margin-right: 2px;
}
