.resultRow {
    display: flex;
    font-family: Lato;
    gap: 10px;
}
.icon {
    margin-top: -5px;
}

.tagContent {
    padding-top: 0rem !important;
    line-height: 24px !important;
}
