.errorPageHeader {
   width: 100%;
}

.errorPageSection {
   margin-top: 10%;
}

.errorPageBody {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
}

.errorPageHeader {
    font-weight: 800;
    font-size: 128px;
    color: rgb(75 85 99);
}

.errorPageSubHeader {
    font-weight: 600;
    font-size: 24px;
}
.errorPageLink {
    font-weight: 600;
    font-size: 24px;
}