.dragListItemWrapper {
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid grey;
    padding: 2px 5px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 15px;
    background-color: aliceblue;
}

.leftWrapper {
    display: flex;
    gap: 5px;
}

.hamburgerMenuButton {
    margin-top: 4px;
    align-self: center;
}

.hideIconProps {
    margin-top: 4px;
    align-self: center;
    cursor: pointer;
}
