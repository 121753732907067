.dynamicPlaceholderInput {
  line-height: 27px;
  font-size: 15px;
  font-family: Lato;
  border: none !important;
}

.dynamicPlaceholderInput__input {
  border-radius: 5px !important;
}


.dynamicPlaceholderInput--singleLine .dynamicPlaceholderInput__control:focus {
  border: none;
}

.dynamicPlaceholderInput--singleLine .dynamicPlaceholderInput__highlighter {
  background-color: white;
}
.dynamicPlaceholderInput--singleLine .dynamicPlaceholderInput__input {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.dynamicPlaceholderInput--singleLine .dynamicPlaceholderInput__input:focus {
  border: 1px solid #40a9ff !important;
  outline: none !important;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.dynamicPlaceholderInput--singleLine .dynamicPlaceholderInput__input:hover {
  border: 1px solid #40a9ff !important;
}

.dynamicPlaceholderInput--multiLine .dynamicPlaceholderInput__control:focus {
  border: none;
}

.dynamicPlaceholderInput--multiLine .dynamicPlaceholderInput__highlighter {
  border: none;
  background-color: white;
}

.dynamicPlaceholderInput--multiLine .dynamicPlaceholderInput__input {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.dynamicPlaceholderInput--multiLine .dynamicPlaceholderInput__input:focus {
  border: 1px solid #40a9ff !important;
  outline: none !important;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.dynamicPlaceholderInput--multiLine .dynamicPlaceholderInput__input:hover {
  border: 1px solid #40a9ff !important;
}
