.App {
  text-align: start;
  background: #E5E5E5;
}

.ant-message {
  z-index: 100000000000;
}

.ant-select-dropdown {
  z-index: 100000;
}

.ant-popover-inner {
  border-radius: 10px !important;
}

.ant-select-selector {
  border-radius: 5px !important;
}

.ant-picker-cell {
  margin-left: -5px;
}

.ant-tour {
  z-index: 1000000 !important;
}

.ant-tour-mask {
  z-index: 1000000 !important;
}
