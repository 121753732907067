.toggleWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 5px;
}

.toggleButton {
    margin-top: 2px !important;
    margin-left: 8px !important;
}

.toggleWrapper > p {
    font-size: 12px !important;
    font-weight: normal;
    color: #808C97;
    margin: 0;
    font-family: Lato;
    font-style: normal;
}
