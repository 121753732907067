.toast :global(.ant-message-custom-content) {
    display: flex;
    justify-content: flex-start;
}

.toast :global(.anticon) {
    display: flex;
    justify-content: center;
    flex-direction: column;
}
