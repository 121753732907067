.resultDetailsWrapper {
    margin-top: 26px;
}

.actionConditionPlaceholder {
    display: flex;
    justify-content: space-between;
    height: 30px;
    border-radius: 4px;
    border: 1px solid #EBEBEB;
    background: rgba(255, 255, 255, 0.8);
    padding: 4px;
    font-size: 14px;
    cursor: pointer;
}

.addConditionBtn_Action {
    float: right;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    display: inline-block;
    color: #2A50E3;
    cursor: pointer;
    padding: 1px 5px;
    border-radius: 2px;
}

.addConditionBtn_Action:hover {
    background: #A9A9A9;
}