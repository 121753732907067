.stageIcon {
    margin-right: 5px;
}

.addStageButton {
    margin-top: -2px;
    text-align: center !important;
    cursor: pointer;
    border-radius: 50% !important;
    background: #EDEDED;
    height: 20px;
    width: 20px !important;
    color: blue;
    line-height: 18px;
}

.addStageButton:hover {
    background: #D2FFCB;
}
