.stageSubtitleContent {
  display: flex;
  align-items: center;
  margin-right: 15px;
  font-size: 10px;
  color: #808c97;
  margin-top: -3px;
}
.stageViewContainer {
  height: 100px;
}
.stageView {
  display: flex;
  flex-direction: row;
  height: 33px;
}

.stageNameLabel {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #060a0f;
  margin: 0;
  padding: 0;
}
